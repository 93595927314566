import queryParser from 'query-string';

import { notify } from 'Components/common/notify';

import { trackEvent } from 'Utils/analytics';
import { checkLastVisitedInDays } from 'Utils/dateUtils';
import {
	getApiCurrencyParameter,
	getApiLanguageParameter,
	getBaseRequestOptions,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { generateFilterKey } from 'Utils/gen';
import { deDupeRecentProductIdList, read } from 'Utils/localStorageUtils';
import { error } from 'Utils/logUtils';
import { getProductFeedSortKey } from 'Utils/productUtils';
import { getCurrentCurrency, getCurrentLanguageCode } from 'Utils/stateUtils';
import {
	addQueryParamsToUrl,
	addQueryParamToString,
	getApiCDNBaseUrlV2,
} from 'Utils/urlUtils';
import { isNumber } from 'Utils/validationUtils';

import { decrementAPICount } from 'Actions/apiCount';
import {
	receiveProductList,
	receiveProductListByCity,
	receiveRecentProductIdList,
	requestProductList,
	requestProductListByCity,
	requestRecentProductIdList,
} from 'Actions/product';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';
import {
	TCityProductListThunkParam,
	TPersonaProductListThunkParam,
} from 'ReduxTypes/persona';

import { ANALYTICS_EVENTS, ANALYTICS_PROPERTIES } from 'Constants/analytics';
import {
	GLOBAL_CITY_CODE,
	NEXT_NEW_PRODUCTS_PAGINATION,
	QUERY_PARAM,
	RECENTLY_VIEWED_ENTITIES,
	SORT_MODE_KEY,
	SORT_TYPE,
	SORT_TYPE_RECENTLY_VIEWED,
} from 'Constants/constants';

import { fetchProductCards } from './product';

const PRODUCTS_PAGINATION_OFFSET_FALLBACK = 0;

export const fetchProductList =
	({
		cityCode,
		params,
		nextPageUrl = null,
		limit = NEXT_NEW_PRODUCTS_PAGINATION,
		lang = 'en',
		req,
	}: any) =>
	(dispatch: any, getState: any) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const unavailableTours = '&include-unavailable=true';
		const sanitizedLimit = isNumber(String(limit))
			? limit
			: NEXT_NEW_PRODUCTS_PAGINATION;
		const limitParam =
			params.indexOf('limit=') > -1 ? '' : `&limit=${sanitizedLimit}`;
		const url = nextPageUrl
			? `${getApiCDNBaseUrlV2({ state: getState() })}${nextPageUrl}`
			: `${getApiCDNBaseUrlV2({
					state: getState(),
			  })}/api/v6/tour-groups/list-by/city/${cityCode}?${params}${limitParam}${currencyParam}${langParam}${unavailableTours}`;

		dispatch(requestProductList({ cityCode, params }));
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: json,
						nextPage: nextPageUrl !== null,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const fetchRecentProductIdList =
	({
		cityCode = GLOBAL_CITY_CODE,
		lang = 'en',
	}: {
		cityCode: string;
		lang: string;
	}) =>
	(dispatch: any) => {
		const params = SORT_TYPE_RECENTLY_VIEWED;
		dispatch(requestRecentProductIdList({ cityCode, params }));
		let products = [];
		const dataFromLocal = read(RECENTLY_VIEWED_ENTITIES);
		products = dataFromLocal
			?.filter(
				({ entityType }: { entityType: string }) =>
					entityType === 'product',
			)
			?.filter(({ lastVisited }: { lastVisited: number }) =>
				lastVisited ? checkLastVisitedInDays(lastVisited, 30) : false,
			)
			?.sort((a: any, b: any) => b.lastVisited - a.lastVisited);

		if (cityCode && cityCode !== GLOBAL_CITY_CODE)
			products = products?.filter(
				({ cityCode: productCityCode }: any) =>
					productCityCode === cityCode,
			);
		else if (cityCode === GLOBAL_CITY_CODE)
			products = deDupeRecentProductIdList(products);

		const productIdList = products?.map((p: any) => p.id) || [];

		dispatch(
			receiveRecentProductIdList({ cityCode, params, productIdList }),
		);
		dispatch(
			fetchProductCards({
				productIds: productIdList,
				lang,
			}),
		);
	};

export const fetchProductListByCategoryId =
	({
		cityCode = GLOBAL_CITY_CODE,
		categoryId,
		params,
		nextPageUrl = null,
		limit = NEXT_NEW_PRODUCTS_PAGINATION,
		lang = 'en',
		req,
		subCategoryIds = [],
	}: any) =>
	(dispatch: any, getState: any) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const cityParams =
			cityCode && cityCode !== GLOBAL_CITY_CODE
				? `&city=${cityCode}`
				: '';
		const limitParam =
			params.indexOf('limit=') > -1 ? '' : `&limit=${limit}`;
		const subCategoryFilterParam = subCategoryIds.length
			? `&subCategoryIds=${subCategoryIds.join(', ')}`
			: '';
		let parsedQueryParams = queryParser.parse(params);

		if (
			parsedQueryParams[QUERY_PARAM.LIMIT] &&
			!isNumber(parsedQueryParams[QUERY_PARAM.LIMIT] as string)
		) {
			parsedQueryParams[QUERY_PARAM.LIMIT] =
				NEXT_NEW_PRODUCTS_PAGINATION.toString();
		}

		if (
			parsedQueryParams[QUERY_PARAM.OFFSET] &&
			!isNumber(parsedQueryParams[QUERY_PARAM.OFFSET] as string)
		) {
			parsedQueryParams[QUERY_PARAM.OFFSET] = String(
				PRODUCTS_PAGINATION_OFFSET_FALLBACK,
			);
		}

		const parsedQueryParamsString =
			queryParser.stringify(parsedQueryParams);

		const url = nextPageUrl
			? `${getApiCDNBaseUrlV2({ state: getState() })}${nextPageUrl}`
			: `${getApiCDNBaseUrlV2({
					state: getState(),
			  })}/api/v6/tour-groups/list-by/category/${categoryId}?${parsedQueryParamsString}${cityParams}${limitParam}${currencyParam}${langParam}${subCategoryFilterParam}`;
		if (!params.includes('categoryId')) {
			//adding categoryId here to be used as redux key
			params = addQueryParamToString(params, { categoryId });
		}
		if (!params.includes('subCategoryIds') && subCategoryIds.length) {
			params = addQueryParamToString(params, {
				subCategoryIds: generateFilterKey(
					...[...subCategoryIds].sort(),
				),
			});
		}
		dispatch(requestProductList({ cityCode, params }));
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: json,
						nextPage: nextPageUrl !== null,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const fetchProductListBySubCategoryId =
	({
		cityCode = GLOBAL_CITY_CODE,
		subCategoryId,
		params,
		nextPageUrl = null,
		limit = NEXT_NEW_PRODUCTS_PAGINATION,
		lang = 'en',
		req,
	}: any) =>
	(dispatch: any, getState: any) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const cityParams =
			cityCode && cityCode !== GLOBAL_CITY_CODE
				? `&city=${cityCode}`
				: '';
		const useSeatMap = `&use-seatmap-prices=1`;
		const limitParam =
			params.indexOf('limit=') > -1 ? '' : `&limit=${limit}`;

		let parsedQueryParams = queryParser.parse(params);

		if (
			parsedQueryParams[QUERY_PARAM.LIMIT] &&
			!isNumber(parsedQueryParams[QUERY_PARAM.LIMIT] as string)
		) {
			parsedQueryParams[QUERY_PARAM.LIMIT] =
				NEXT_NEW_PRODUCTS_PAGINATION.toString();
		}

		if (
			parsedQueryParams[QUERY_PARAM.OFFSET] &&
			!isNumber(parsedQueryParams[QUERY_PARAM.OFFSET] as string)
		) {
			parsedQueryParams[QUERY_PARAM.OFFSET] = String(
				PRODUCTS_PAGINATION_OFFSET_FALLBACK,
			);
		}

		const parsedQueryParamsString =
			queryParser.stringify(parsedQueryParams);

		const url = nextPageUrl
			? `${getApiCDNBaseUrlV2({ state: getState() })}${nextPageUrl}`
			: `${getApiCDNBaseUrlV2({
					state: getState(),
			  })}/api/v6/tour-groups/list-by/sub-category/${subCategoryId}?${parsedQueryParamsString}${cityParams}${limitParam}${currencyParam}${langParam}${useSeatMap}`;

		if (!params.includes('subCategoryId')) {
			//adding subCateogryId here to be used as redux key
			params = addQueryParamToString(params, {
				subCategoryId,
			});
		}
		dispatch(requestProductList({ cityCode, params }));
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: json,
						nextPage: nextPageUrl !== null,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const fetchProductListByTag =
	({
		tag,
		cityCode,
		params,
		nextPageUrl = null,
		limit = 24,
		lang = 'en',
		req,
	}: any) =>
	(dispatch: any, getState: any) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const limitParam =
			params.indexOf('limit=') > -1 ? '' : `&limit=${limit}`;
		const url = nextPageUrl
			? `${getApiCDNBaseUrlV2({ state: getState() })}${nextPageUrl}`
			: `${getApiCDNBaseUrlV2({
					state: getState(),
			  })}/api/v6/tour-groups/list-by/tag/${tag}?${params}${limitParam}${currencyParam}${langParam}`;
		dispatch(requestProductList({ cityCode, params }));
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode,
						params,
						response: json,
						nextPage: nextPageUrl !== null,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const fetchProductListByPersonaId =
	({
		city,
		personaAffinityId,
		offset = 0,
		limit = NEXT_NEW_PRODUCTS_PAGINATION,
		useSeatMap = true,
		includeHidden = false,
		includeUnavailable = false,
		req,
		categories = [],
		subCategories = [],
	}: TPersonaProductListThunkParam) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const lang = getCurrentLanguageCode(state).toUpperCase();
		const currencyCode = getCurrentCurrency(state);
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const urlBase = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v6/tour-groups/list-by/persona-affinity/${personaAffinityId}`;

		const url = `${addQueryParamsToUrl(urlBase, {
			city,
			'use-seatmap-prices': useSeatMap ? 1 : 0,
			'include-hidden': includeHidden,
			'include-unavailable': includeUnavailable,
			language: lang,
			offset: isNumber(String(offset))
				? offset
				: PRODUCTS_PAGINATION_OFFSET_FALLBACK,
			limit: isNumber(String(limit))
				? limit
				: NEXT_NEW_PRODUCTS_PAGINATION,
			categoryIds: categories.join(','),
			subCategoryIds: subCategories.join(','),
		})!}${currencyParam}`;

		const personaParam = `pa-${personaAffinityId}-${
			generateFilterKey(...[...categories, ...subCategories].sort()) ||
			'DEFAULT'
		}`;

		dispatch(
			requestProductList({
				cityCode: city.toUpperCase(),
				params: personaParam,
			}),
		);
		const requestOptions = req
			? { headers: { cookie: req.headers.cookie } }
			: {};
		const options = getBaseRequestOptions(getState(), requestOptions);

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductList({
						cityCode: city.toUpperCase(),
						params: personaParam,
						response: json,
						url,
					}),
				);
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};

export const fetchProductCardsByCityCode =
	({
		cityCode = '',
		limit,
		offset,
		sortType = SORT_MODE_KEY.POPULARITY,
		subCategories = [],
		pageNumber,
	}: TCityProductListThunkParam) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		const lang = getCurrentLanguageCode(state).toUpperCase();
		const currencyCode = getCurrentCurrency(state);
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);

		const urlBase = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v3/cities/${cityCode}/product-cards/`;

		const url = `${addQueryParamsToUrl(urlBase, {
			language: lang,
			offset,
			limit,
			subcategories: subCategories?.join(',') || '',
			'sort-type': sortType,
		})!}${currencyParam}`;

		const params = getProductFeedSortKey(sortType, subCategories);

		dispatch(
			requestProductListByCity({
				cityCode: cityCode.toUpperCase(),
				params,
			}),
		);

		return fetch(url)
			.then(response => response.json())
			.then(json => {
				dispatch(
					receiveProductListByCity({
						cityCode: cityCode.toUpperCase(),
						params,
						response: json,
						nextPage: true,
						url,
					}),
				);
				const { result } = json;
				const { productCards } = result;
				const { total } = productCards;
				if (sortType === SORT_TYPE.DISCOUNT && pageNumber) {
					trackEvent({
						eventName: ANALYTICS_EVENTS.INFINITE_SCROLL_PAGE_LOAD,
						[ANALYTICS_PROPERTIES.PAGE_NUMBER]: pageNumber,
						[ANALYTICS_PROPERTIES.LIMIT]: limit,
						[ANALYTICS_PROPERTIES.TOTAL_CARDS]: total,
					});
				}
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				dispatch(decrementAPICount());
				notify.showNetworkError(err);
			});
	};
